import { collection, Firestore, getDocs, query, where } from 'firebase/firestore/lite';
import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { VideoData } from '../classes/videos';
import ListYoutube from './common/list-youtube';
function Busqueda(props: { videos: VideoData[] ,db : Firestore}) {
    const { search } = useParams();
    const [filtrado, setfiltrado] = useState<VideoData[]>([]);
    useEffect(() => {
        const q = query(
            collection(props.db, "videos"),
            where("tipo", "==", "clase"),
          );
          async function doThis() {
            const querySnapshot = await getDocs(q);
            let dataT: any = [];
            querySnapshot.forEach((val) => {
              if (val.exists()) {
                const aa: any = val.data();
                dataT.push(aa);
              }
            });
            props.videos.forEach(element => {
                dataT.push(element);
            });
            setfiltrado(dataT.filter((x: VideoData) => x.titulo.toLowerCase().includes(search?.toLowerCase() || "")));
          }
          doThis();
    }, [search])

    return <>
        <Col align="center">
            <h2 className='area-title center'>Busqueda : {search}</h2>
            <ListYoutube data={filtrado} image={false} fulldata={true}/>
        </Col>
    </>
}
export default Busqueda;