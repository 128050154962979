import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { VideoData } from "../classes/videos";
import ListYoutube from "./common/list-youtube";
import PlayList from "./common/playlist";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectCoverflow, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Heading, Link, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
SwiperCore.use([EffectCoverflow, Pagination]);
function UltimosCurso(props: { cursos: VideoData[] }) {
  const navigate = useNavigate();
  const openPlaylist = (dat: VideoData) => {
    navigate("/" + dat.tipo + "/" + dat.id);
  };

  return (
    <>
      <Col >
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={5}
          initialSlide={5}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2,
            slideShadows: false,
          }}
          pagination={true}
          className="mySwiper"
        >
          {props.cursos.map((dat: VideoData) => (
            <SwiperSlide key={dat.id}>
              <Card style={{ width: "20rem", height: "16rem",backgroundImage: `url(/${dat.id}.jpg)`,border: "solid black 0px", backgroundPosition:'Right',backgroundSize:'cover'}}>
                <Card.Body className="d-flex flex-column">
                  <Card.Title style={{fontSize: "1.2rem"}}> {dat.titulo}</Card.Title>
                  <Button className="mt-auto btn-ultimoscursos" onClick={() => openPlaylist(dat)}>
                  Abrir curso
                  </Button>
                </Card.Body>
              </Card>
            </SwiperSlide>
          ))}
          <br/>
        </Swiper>
      </Col>
    </>
  );
}
export default UltimosCurso;
