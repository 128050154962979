import {
  collection,
  Firestore,
  getDocs,
  query,
  where,
} from "firebase/firestore/lite";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { VideoData } from "../classes/videos";
import ListYoutube from "./common/list-youtube";
import PlayList from "./common/playlist";

function Curso(props: { cursos: VideoData[] }) {
  const [inputValue, setInputValue] = useState("");
  const [activeList, setActiveList] = useState<VideoData[]>(props.cursos);
  return (
    <>
      <Row>
        <Col>
          <input
            className="search"
            type="text"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setInputValue(e.target.value);
            }}
            placeholder="¿Qué vas a estudiar?"
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <ListYoutube
            data={activeList.filter((x) =>
              x.titulo.toLowerCase().includes(inputValue.toLowerCase())
            )}
            image={true}
            fulldata={false}
          />
        </Col>
      </Row>
    </>
  );
}
export default Curso;
