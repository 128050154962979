import React, { useEffect, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs, query, getDoc, doc, where} from 'firebase/firestore/lite';
import { ChakraProvider, Box } from '@chakra-ui/react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import { VideoData } from './classes/videos';
import Curso from './components/curso';
import Navigation from './components/layout/navbar';
import { BrowserRouter as Router, createBrowserRouter, redirect, Route, RouterProvider, Routes, useNavigate } from 'react-router-dom';
import Home from './components/home/home';
import Escuela from './components/escuela';
import Footer from './components/layout/footer';
import PlayList from './components/common/playlist';
import VideoYoutube from './components/common/video-youtube';
import Busqueda from './components/busqueda';
import Carreras from './components/carreras';
import Upload from './components/common/upload';
import { useToast } from '@chakra-ui/react'
import { FaInstagram } from 'react-icons/fa';
import "firebase/compat/storage";
import Clase from './components/clase';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB1,
  authDomain: "aldetv-65e2c.firebaseapp.com",
  projectId: "aldetv-65e2c",
  storageBucket: "aldetv-65e2c.appspot.com",
  messagingSenderId: "514702377953",
  appId: "1:514702377953:web:afd596197595e2eb2488a0",
  measurementId: "G-XGMTFWDBB0"
};

function App() {

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const db = getFirestore(app);
  const [cursos, setcursos] = useState<VideoData[]>([]);
  const [videos, setVideos] = useState<VideoData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [insta, setinsta] = useState<any>([]);
  function navigate(path: string) {
    router.navigate(path)
  }
  const toast = useToast()
  useEffect(() => {
    const q = query(collection(db, "videos"));
    // const q = query(collection(db, "videos"), where("tipo", "==", "curso"));
    async function doThis() {
      const docRef = doc(db, "home", "1");
      const docSnap  = await getDoc(docRef);
      if (docSnap.exists()) {
       setinsta(docSnap.data());
      } 
      const querySnapshot = await getDocs(q);
      let dataT: any = [];
      querySnapshot.forEach(val => {
        if (val.exists()) {
          const aa: any = val.data();
          dataT.push(aa);
        }
      });
      setVideos(dataT)
      setcursos(dataT.filter((x: VideoData) => x.tipo === "curso"));
      setLoading(false)
    }
    doThis();
    toast({
      position: 'bottom-left',
      render: () => (
        <Box color='white' p={3} bg='blue.500' className='rounded'>
          <a href='https://www.instagram.com/alde.cienciasmedicas/' target="_blank">
            <Row>
              <Col lg={10}>Seguinos en instagram y no te pierdas nada de la facu </Col>
              <Col lg={2} className="pt-2"> <FaInstagram color="white" size={"2em"}/></Col>
            </Row>
          </a>
        </Box>
      ),
    })
  }, [])
 
 

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          {loading ? (
            <div className="loader loaderHome" />
          ) : (
            <Home cursos={cursos} insta={[insta.a, insta.b, insta.c]} />
          )}
        </>
      ),
    },
    {
      path: "/escuela/:escuelaId",
      element: <Escuela db={db} />,
    },
    {
      path: "/curso/:videoId",
      element: (
        <Row>
          <PlayList />
        </Row>
      ),
    },
    {
      path: "/cursos",
      element: (
        <>
          <div className="section-title bgverde">
            <h2 className='bold blanco'>Cursos</h2>
          </div>
          <br></br>
          <Container className="my-2">
            <Row>
              <Curso cursos={cursos} />
            </Row>
          </Container>
        </>
      ),
    },
    {
      path: "/carreras",
      element: (
        <section id="services" className="services">
          <Container id="carrera">
            <Carreras />
          </Container>
        </section>
      ),
    },
    {
      path: "/buscar/:search",
      element: (
        <Row>
          <Busqueda videos={cursos} db={db} />
        </Row>
      ),
    },
    {
      path: "/clase/:videoId",
      element: (
        <Clase videos={videos} />
      ),
    },
    {
      path: "*",
      element: <div>EROR 404, PAGINA NO ENCONTRADA</div>,
    },
    {
      path: "upload",
      element: <Upload />
    },
  ]);

  return (
    <ChakraProvider>
      <Navigation router={router} />
      <RouterProvider router={router} />
      <Footer />
    </ChakraProvider>
  );
}

export default App;
