import { collection, Firestore, getDocs, query, where } from 'firebase/firestore/lite';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { VideoData } from '../classes/videos';
import Anio from './anio';
import ListYoutube from './common/list-youtube';


function ClaseDeRepaso(props: { escuelaId: number, db: Firestore}) {
    const [loading, setLoading] = useState<boolean>(true);
    const [data0, setData0] = useState<VideoData[]>([]);
    const [data1, setData1] = useState<VideoData[]>([]);
    const [data2, setData2] = useState<VideoData[]>([]);
    const [data3, setData3] = useState<VideoData[]>([]);
    const [data4, setData4] = useState<VideoData[]>([]);
    const [data5, setData5] = useState<VideoData[]>([]);

    const [inputValue, setInputValue] = useState("");
    const [activeList, setActiveList] = useState<VideoData[]>([]);

    useEffect(() => {
        const q = query(
            collection(props.db, "videos"),
            where("escuela", "==", props.escuelaId)
          );
          async function doThis() {
            const querySnapshot = await getDocs(q);
            let dataT: any = [];
            querySnapshot.forEach((val) => {
              if (val.exists()) {
                const aa: any = val.data();
                dataT.push(aa);
              }
            });
            var datat0 = dataT.filter((x: { anio: number; escuela: number; }) => x.anio === 0 && x.escuela === props.escuelaId)
            var datat1 = dataT.filter((x: { anio: number; escuela: number; }) => x.anio === 1 && x.escuela === props.escuelaId)
            setData0(datat0)
            setData1(datat1)
            setData2(dataT.filter((x: { anio: number; escuela: number; }) => x.anio === 2 && x.escuela === props.escuelaId))
            setData3(dataT.filter((x: { anio: number; escuela: number; })=> x.anio === 3 && x.escuela === props.escuelaId))
            setData4(dataT.filter((x: { anio: number; escuela: number; }) => (x.anio === 4 || x.anio === 5) && x.escuela === props.escuelaId))
            if(props.escuelaId === 1 || props.escuelaId === 2){
                setActiveList(datat0)
            }else{
                setActiveList(datat1)
            }
            setLoading(false)
        }
        doThis();

    }, [])

    return (
      <>
        {loading ? (
          <div className="loader" />
        ) : (
          <>
          <Row className="bgverde">
            <Col className='section-title'>
            <h2 className='bold blanco'>CLASES DE REPASO</h2>
            </Col>
          </Row>
          <div className='bgverde pb-4'>
          <Row className=" bold verde rowAnios">
              {data0.length > 0 && (
                <Col className="anio">
                  <p onClick={() => {setActiveList(data0);}} className="text-center">
                    Clases y cursos
                  </p>
                </Col>
              )}
              {data1.length > 0 && (
                <Col className="anio">
                  <p
                    onClick={() => {
                      setActiveList(data1);
                    }}
                    className="text-center"
                  >
                    1° AÑO
                  </p>
                </Col>
              )}
              {data2.length > 0 && (
                <Col className="anio">
                  <p
                    onClick={() => {
                      setActiveList(data2);
                    }}
                    className="text-center"
                  >
                    2° AÑO
                  </p>
                </Col>
              )}
              {data3.length > 0 && (
                <Col className="anio">
                  <p
                    onClick={() => {
                      setActiveList(data3);
                    }}
                    className="text-center"
                  >
                    3° AÑO
                  </p>
                </Col>
              )}
              {data4.length > 0 && (
                <Col className="anio">
                  <p
                    onClick={() => {
                      setActiveList(data4);
                    }}
                    className="text-center"
                  >
                    4° AÑO
                  </p>
                </Col>
              )}
              {data4.length > 0 && (
                <Col className="anio">
                  <p
                    onClick={() => {
                      setActiveList(data4);
                    }}
                    className="text-center"
                  >
                    5° AÑO
                  </p>
                </Col>
              )}
            </Row>
          </div>

            
            <Container className='mt-4'>
              <Row>
                <Col>
                  <input
                    className="search"
                    type="text"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setInputValue(e.target.value);
                    }}
                    placeholder="¿Qué vas a estudiar?"
                  />
                </Col>
              </Row>
              <Row>
                <Anio
                  videos={activeList.filter((x) =>
                    x.titulo.toLowerCase().includes(inputValue.toLowerCase())
                  )}
                />
              </Row>
            </Container>
          </>
        )}
      </>
    );
}
export default ClaseDeRepaso;