import {  Firestore } from 'firebase/firestore/lite';
import React from 'react';
import { useParams } from 'react-router-dom';
import ClaseDeRepaso from './clase-de-repaso';


function Escuela(props: { db: Firestore }) {
    const { escuelaId } = useParams();
    const escuela = Number.parseInt(escuelaId || "0");
    return <>
       <ClaseDeRepaso escuelaId={escuela} db={props.db} />
    </>
}
export default Escuela;