import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
} from "firebase/firestore/lite";

function Upload() {
  const firebaseConfig = {
    apiKey: "AIzaSyCcP4M9lHXvePolUl-MCQMYYTSM9SoJqzA",
    authDomain: "aldetv-65e2c.firebaseapp.com",
    projectId: "aldetv-65e2c",
    storageBucket: "aldetv-65e2c.appspot.com",
    messagingSenderId: "514702377953",
    appId: "1:514702377953:web:afd596197595e2eb2488a0",
    measurementId: "G-XGMTFWDBB0",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  function subirData() {
    const datos = [
        {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "zxAPRrfg1nU",
            "tipo": "clase",
            "titulo": "DXI: Fundamentos"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "4Aqzjdv-ZjA",
            "tipo": "clase",
            "titulo": "Farmacología: Adrenérgicos y antiadrenérgicos - Luciano García"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "Dz9DTNKQJUg",
            "tipo": "clase",
            "titulo": "Farmacología: Farmacocinética - Dora Prada"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "1AYPG9A-cH4",
            "tipo": "clase",
            "titulo": "Farmacología: Farmacocinética - Pablo Lapalma"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "26ggl81uwQI",
            "tipo": "clase",
            "titulo": "Farmacología: Farmacocinética clínica - Dora Prada"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "VZJM4t4-I0E",
            "tipo": "clase",
            "titulo": "Farmacología: Farmacodinamia - Pablo Lapalma"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "XhMgcavoHaw",
            "tipo": "clase",
            "titulo": "Farmacología: SNA - Dora Prada"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "ZSH8HI486Jg",
            "tipo": "clase",
            "titulo": "Fisiología: Sme. Metabólico y DBT - Pablo Arias"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "8dWFuvVJh0Q",
            "tipo": "clase",
            "titulo": "Fisiopatología: Ateromatosis - Jorge Molinas"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "lREUCRLXKfI",
            "tipo": "clase",
            "titulo": "Fisiopatología: Diabetes - Pablo Arias"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "y3sldYm_Wgk",
            "tipo": "clase",
            "titulo": "Fisiopatología: Disnea y Tos - Iñaki Martinez"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "L-L8dpkFD6Y",
            "tipo": "clase",
            "titulo": "Fisiopatología: Hemostasia - Jorge Molinas"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "r1jRrKLOzHM",
            "tipo": "clase",
            "titulo": "Fisiopatología: Inflamación - Federico Calderone"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "trGrA951_zw",
            "tipo": "clase",
            "titulo": "Fisiopatología: Inflamación - Jorge Molinas"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "kvwEKTYg2dc",
            "tipo": "clase",
            "titulo": "Fisiopatología: la injuria y la célula - Federico Calderone"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "uxHsWHgARhE",
            "tipo": "clase",
            "titulo": "Fisiopatología: Trastornos Hemodinámicos - Federico Calderone"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "_wsHwq9jSTU",
            "tipo": "clase",
            "titulo": "Infectología: Enf. Endémicas - Damian Lerman"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "98W5hF6nSPs",
            "tipo": "clase",
            "titulo": "Integrador por UP 1 a 5 - Diego Ainsuain"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "oURGXw61uE0",
            "tipo": "clase",
            "titulo": "Integrador por UP 5, 6, 7 y 8 - Diego Ainsuain"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "7uzwQx6F3CY",
            "tipo": "clase",
            "titulo": "Integrador por UP 6 a 10 - Diego Ainsuain"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "PXT1kO4ezk4",
            "tipo": "clase",
            "titulo": "Microbiología: generalidades - Helvio Vila"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "JpHJ3DO0AdE",
            "tipo": "clase",
            "titulo": "Psiquiatría: Estrés - José García Riera"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "VDQVm_eaEHA",
            "tipo": "clase",
            "titulo": "Semiología: Aparato Digestivo I - Belén Montenegro"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "3gOfcgYyPgI",
            "tipo": "clase",
            "titulo": "Semiología: Clase de consulta - Belén Montenegro"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "JzmgK-T4gRk",
            "tipo": "clase",
            "titulo": "Semiología: Dolor abdominal y abdomen agudo - Gabriela Garrido"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "UPDkbPpHrh0",
            "tipo": "clase",
            "titulo": "Semiología: HC y motivos de consulta I - Belén Montenegro"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "decxVvMq5fs",
            "tipo": "clase",
            "titulo": "Semiología: HC y motivos de consulta II - Belén Montenegro"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "VdplYI3myZc",
            "tipo": "clase",
            "titulo": "Semiología: Ictericia y HTA - Marianela Bertoya"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "HF5byhlAEec",
            "tipo": "clase",
            "titulo": "Semiología: integrador - Gabriela Garrido"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "jSjqkn6R3n8",
            "tipo": "clase",
            "titulo": "Semiología: MC Cardiovascular - Belén Montenegro"
          },
          {
            "anio": 3,
            "area": "Injuria",
            "escuela": 3,
            "id": "8t9dDGXxvA4",
            "tipo": "clase",
            "titulo": "Semiología: MC Respiratorio - Belén Montenegro"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "zxAPRrfg1nU",
            "tipo": "clase",
            "titulo": "DXI: Fundamentos"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "4Aqzjdv-ZjA",
            "tipo": "clase",
            "titulo": "Farmacología: Adrenérgicos y antiadrenérgicos - Luciano García"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "Dz9DTNKQJUg",
            "tipo": "clase",
            "titulo": "Farmacología: Farmacocinética - Dora Prada"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "1AYPG9A-cH4",
            "tipo": "clase",
            "titulo": "Farmacología: Farmacocinética - Pablo Lapalma"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "26ggl81uwQI",
            "tipo": "clase",
            "titulo": "Farmacología: Farmacocinética clínica - Dora Prada"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "VZJM4t4-I0E",
            "tipo": "clase",
            "titulo": "Farmacología: Farmacodinamia - Pablo Lapalma"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "XhMgcavoHaw",
            "tipo": "clase",
            "titulo": "Farmacología: SNA - Dora Prada"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "ZSH8HI486Jg",
            "tipo": "clase",
            "titulo": "Fisiología: Sme. Metabólico y DBT - Pablo Arias"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "8dWFuvVJh0Q",
            "tipo": "clase",
            "titulo": "Fisiopatología: Ateromatosis - Jorge Molinas"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "lREUCRLXKfI",
            "tipo": "clase",
            "titulo": "Fisiopatología: Diabetes - Pablo Arias"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "y3sldYm_Wgk",
            "tipo": "clase",
            "titulo": "Fisiopatología: Disnea y Tos - Iñaki Martinez"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "L-L8dpkFD6Y",
            "tipo": "clase",
            "titulo": "Fisiopatología: Hemostasia - Jorge Molinas"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "r1jRrKLOzHM",
            "tipo": "clase",
            "titulo": "Fisiopatología: Inflamación - Federico Calderone"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "trGrA951_zw",
            "tipo": "clase",
            "titulo": "Fisiopatología: Inflamación - Jorge Molinas"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "kvwEKTYg2dc",
            "tipo": "clase",
            "titulo": "Fisiopatología: la injuria y la célula - Federico Calderone"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "uxHsWHgARhE",
            "tipo": "clase",
            "titulo": "Fisiopatología: Trastornos Hemodinámicos - Federico Calderone"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "_wsHwq9jSTU",
            "tipo": "clase",
            "titulo": "Infectología: Enf. Endémicas - Damian Lerman"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "98W5hF6nSPs",
            "tipo": "clase",
            "titulo": "Integrador por UP 1 a 5 - Diego Ainsuain"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "oURGXw61uE0",
            "tipo": "clase",
            "titulo": "Integrador por UP 5, 6, 7 y 8 - Diego Ainsuain"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "7uzwQx6F3CY",
            "tipo": "clase",
            "titulo": "Integrador por UP 6 a 10 - Diego Ainsuain"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "PXT1kO4ezk4",
            "tipo": "clase",
            "titulo": "Microbiología: generalidades - Helvio Vila"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "JpHJ3DO0AdE",
            "tipo": "clase",
            "titulo": "Psiquiatría: Estrés - José García Riera"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "VDQVm_eaEHA",
            "tipo": "clase",
            "titulo": "Semiología: Aparato Digestivo I - Belén Montenegro"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "3gOfcgYyPgI",
            "tipo": "clase",
            "titulo": "Semiología: Clase de consulta - Belén Montenegro"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "JzmgK-T4gRk",
            "tipo": "clase",
            "titulo": "Semiología: Dolor abdominal y abdomen agudo - Gabriela Garrido"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "UPDkbPpHrh0",
            "tipo": "clase",
            "titulo": "Semiología: HC y motivos de consulta I - Belén Montenegro"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "decxVvMq5fs",
            "tipo": "clase",
            "titulo": "Semiología: HC y motivos de consulta II - Belén Montenegro"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "VdplYI3myZc",
            "tipo": "clase",
            "titulo": "Semiología: Ictericia y HTA - Marianela Bertoya"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "HF5byhlAEec",
            "tipo": "clase",
            "titulo": "Semiología: integrador - Gabriela Garrido"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "jSjqkn6R3n8",
            "tipo": "clase",
            "titulo": "Semiología: MC Cardiovascular - Belén Montenegro"
          },
          {
            "anio": 2,
            "area": "SHySM",
            "escuela": 3,
            "id": "8t9dDGXxvA4",
            "tipo": "clase",
            "titulo": "Semiología: MC Respiratorio - Belén Montenegro"
          },
          {
            "anio": 2,
            "area": "TyTL",
            "escuela": 3,
            "id": "47e--AXMmP0",
            "tipo": "clase",
            "titulo": "Anatomía: Laringe y segmentación broncopulmonar - Eugenia Cabral"
          },
          {
            "anio": 2,
            "area": "TyTL",
            "escuela": 3,
            "id": "TyAtEupui88",
            "tipo": "clase",
            "titulo": "Embriología: Corazón - Stella Roma"
          },
          {
            "anio": 2,
            "area": "TyTL",
            "escuela": 3,
            "id": "A_BOF_w8akU",
            "tipo": "clase",
            "titulo": "Física: Aparato respiratorio - Laureano Albornoz"
          },
          {
            "anio": 2,
            "area": "TyTL",
            "escuela": 3,
            "id": "KE9JP028c_Y",
            "tipo": "clase",
            "titulo": "Física: Hemodinamia - Mariana Ferrero"
          },
          {
            "anio": 2,
            "area": "TyTL",
            "escuela": 3,
            "id": "8bVaNsCHT1U",
            "tipo": "clase",
            "titulo": "Física: Leyes de la hemodinamia - Laureano Albornoz"
          },
          {
            "anio": 2,
            "area": "TyTL",
            "escuela": 3,
            "id": "xVklu47vIt0",
            "tipo": "clase",
            "titulo": "Física: Leyes y aparato respiratorio - Mariana Ferrero"
          },
          {
            "anio": 2,
            "area": "TyTL",
            "escuela": 3,
            "id": "cWjXhDYUT78",
            "tipo": "clase",
            "titulo": "Física: Teorema de Bernoulli - Laureano Albornoz"
          },
          {
            "anio": 2,
            "area": "TyTL",
            "escuela": 3,
            "id": "Psj1M3x7U0c",
            "tipo": "clase",
            "titulo": "Fisiología: Ciclo Cardíaco - Jorge Molinas"
          },
          {
            "anio": 2,
            "area": "TyTL",
            "escuela": 3,
            "id": "MjOZq8W0lPU",
            "tipo": "clase",
            "titulo": "Fisiología: Ciclo Cardíaco - Pablo Arias"
          },
          {
            "anio": 2,
            "area": "TyTL",
            "escuela": 3,
            "id": "MhjIZ8enH64",
            "tipo": "clase",
            "titulo": "Fisiología: ECG y regulación de la PA - Pablo Arias"
          },
          {
            "anio": 2,
            "area": "TyTL",
            "escuela": 3,
            "id": "zQO77L4P6U4",
            "tipo": "clase",
            "titulo": "Fisiología: Equilibrio y Sistema Vestibular - Juan Jairala"
          },
          {
            "anio": 2,
            "area": "TyTL",
            "escuela": 3,
            "id": "0dtbnPbAZt8",
            "tipo": "clase",
            "titulo": "Fisiología: Estrés - Pablo Arias"
          },
          {
            "anio": 2,
            "area": "TyTL",
            "escuela": 3,
            "id": "Ll0efhf9ZaM",
            "tipo": "clase",
            "titulo": "Fisiología: Generación del ritmo cardíaco - Pablo Arias"
          },
          {
            "anio": 2,
            "area": "TyTL",
            "escuela": 3,
            "id": "M59-leS_x_k",
            "tipo": "clase",
            "titulo": "Fisiología: Integrador de SNA y Ap. Respiratorio - Jorge Molinas"
          },
          {
            "anio": 2,
            "area": "TyTL",
            "escuela": 3,
            "id": "iBUHS618VmU",
            "tipo": "clase",
            "titulo": "Fisiología: Regulación de la PA parte I - Guillermo Grigioni"
          },
          {
            "anio": 2,
            "area": "TyTL",
            "escuela": 3,
            "id": "L76afB7ZgII",
            "tipo": "clase",
            "titulo": "Fisiología: Regulación de la PA parte II - Guillermo Grigioni"
          },
          {
            "anio": 2,
            "area": "TyTL",
            "escuela": 3,
            "id": "1dLQqZC1IrQ",
            "tipo": "clase",
            "titulo": "Fisiología: Regulación del movimiento - Juan Jairala"
          },
          {
            "anio": 2,
            "area": "TyTL",
            "escuela": 3,
            "id": "JjajFslzMpQ",
            "tipo": "clase",
            "titulo": "Fisiología: Regulación del VMC - Pablo Arias"
          },
          {
            "anio": 2,
            "area": "TyTL",
            "escuela": 3,
            "id": "VM63uKj3MfI",
            "tipo": "clase",
            "titulo": "Fisiología: Sistema Vestibular - Juan Jairala"
          },
          {
            "anio": 2,
            "area": "TyTL",
            "escuela": 3,
            "id": "el11mugkAwU",
            "tipo": "clase",
            "titulo": "Fisología: Ejercicio - Jorge Molinas"
          },
          {
            "anio": 2,
            "area": "TyTL",
            "escuela": 3,
            "id": "EZtx48Q8EHY",
            "tipo": "clase",
            "titulo": "Integrador por UP - Carlos Dabin"
          },
          {
            "anio": 2,
            "area": "TyTL",
            "escuela": 3,
            "id": "EBe7jk2W61Q",
            "tipo": "clase",
            "titulo": "Integrador por UP - Jorge Molinas"
          },
          {
            "anio": 2,
            "area": "TyTL",
            "escuela": 3,
            "id": "S0kiAszglKY",
            "tipo": "clase",
            "titulo": "Integrador por UP - Julia Baravalle"
          },
          {
            "anio": 2,
            "area": "Sexualidad",
            "escuela": 3,
            "id": "wfjCmFuI1uY",
            "tipo": "clase",
            "titulo": "Anatomía: Ap Reproductor femenino - Eugenia Cabral"
          },
          {
            "anio": 2,
            "area": "Sexualidad",
            "escuela": 3,
            "id": "A0ckX44u3Lc",
            "tipo": "clase",
            "titulo": "Anatomía: Ap Reproductor masculino - Eugenia Cabral"
          },
          {
            "anio": 2,
            "area": "Sexualidad",
            "escuela": 3,
            "id": "TJJ-3c21Mrk",
            "tipo": "clase",
            "titulo": "Anatomía: Ap. Reproductor femenino y masculino - Eugenia Cabral"
          },
          {
            "anio": 2,
            "area": "Sexualidad",
            "escuela": 3,
            "id": "rJvJxW7-CiU",
            "tipo": "clase",
            "titulo": "Anatomía: Pelvis ósea - Eugenia Cabral"
          },
          {
            "anio": 2,
            "area": "Sexualidad",
            "escuela": 3,
            "id": "-teylIoEw0Y",
            "tipo": "clase",
            "titulo": "Anatomía: repaso - Eugenia Cabral"
          },
          {
            "anio": 2,
            "area": "Sexualidad",
            "escuela": 3,
            "id": "M_dhmua4CnU",
            "tipo": "clase",
            "titulo": "Embriología: Aparato genital - Stella Roma"
          },
          {
            "anio": 2,
            "area": "Sexualidad",
            "escuela": 3,
            "id": "zOJi6VtOYNg",
            "tipo": "clase",
            "titulo": "Fisiología: clase de consulta - Pablo Arias"
          },
          {
            "anio": 2,
            "area": "Sexualidad",
            "escuela": 3,
            "id": "aTWCetvKZdc",
            "tipo": "clase",
            "titulo": "Fisiología: clase de consulta - Pablo Arias y Martín Habib"
          },
          {
            "anio": 2,
            "area": "Sexualidad",
            "escuela": 3,
            "id": "jKh2QnGrin8",
            "tipo": "clase",
            "titulo": "Fisiología: Desarrollo puberal - Pablo Arias"
          },
          {
            "anio": 2,
            "area": "Sexualidad",
            "escuela": 3,
            "id": "qa1C6ZdaaFA",
            "tipo": "clase",
            "titulo": "Fisiología: Desarrollo puberal y ciclo menstrual - Pablo Arias"
          },
          {
            "anio": 2,
            "area": "Sexualidad",
            "escuela": 3,
            "id": "3EMKQfp7qBU",
            "tipo": "clase",
            "titulo": "Fisiología: Eje HT-HF-gonadal - Jorge Molinas"
          },
          {
            "anio": 2,
            "area": "Sexualidad",
            "escuela": 3,
            "id": "C9lOgRmtUEs",
            "tipo": "clase",
            "titulo": "Fisiología: Eje HT-HF-gonadal - Pablo Arias"
          },
          {
            "anio": 2,
            "area": "Sexualidad",
            "escuela": 3,
            "id": "t1Sa2eOqmO8",
            "tipo": "clase",
            "titulo": "Fisiología: Eje HT-HF-testicular - Inés de María"
          },
          {
            "anio": 2,
            "area": "Sexualidad",
            "escuela": 3,
            "id": "7VgBEFlC7VM",
            "tipo": "clase",
            "titulo": "Fisiología: Integrador parte I - Pablo Arias"
          },
          {
            "anio": 2,
            "area": "Sexualidad",
            "escuela": 3,
            "id": "1svK3yYsmLs",
            "tipo": "clase",
            "titulo": "Fisiología: Pubertad, Menopausia y Andropausia- Pablo Arias"
          },
          {
            "anio": 2,
            "area": "Sexualidad",
            "escuela": 3,
            "id": "ct7DAX3uk0o",
            "tipo": "clase",
            "titulo": "Medicina y Sociedad: Integrador - Emiliano Scaglia"
          },
          {
            "anio": 1,
            "area": "CyD",
            "escuela": 3,
            "id": "SEp9UX1_gq4",
            "tipo": "clase",
            "titulo": "Anatomía: diartrosis y columna - Eugenia Cabral"
          },
          {
            "anio": 1,
            "area": "CyD",
            "escuela": 3,
            "id": "XG5eFbaKR5s",
            "tipo": "clase",
            "titulo": "Anatomía: huesos del cráneo I - Eugenia Cabral"
          },
          {
            "anio": 1,
            "area": "CyD",
            "escuela": 3,
            "id": "WmEcMcA8v0k",
            "tipo": "clase",
            "titulo": "Anatomía: huesos del cráneo II - Eugenia Cabral"
          },
          {
            "anio": 1,
            "area": "CyD",
            "escuela": 3,
            "id": "DvN445PniC8",
            "tipo": "clase",
            "titulo": "Anatomía: huesos y articulaciones - Eugenia Cabral"
          },
          {
            "anio": 1,
            "area": "CyD",
            "escuela": 3,
            "id": "xw77mOCdwFg",
            "tipo": "clase",
            "titulo": "Anatomía: marcha - Eugenia Cabral"
          },
          {
            "anio": 1,
            "area": "CyD",
            "escuela": 3,
            "id": "PLVHL_sCmXigFK_zrYE2E2zehLQvh0stD9",
            "tipo": "clase",
            "titulo": "Clases de Fisiología"
          },
          {
            "anio": 1,
            "area": "CyD",
            "escuela": 3,
            "id": "PLVHL_sCmXigFKDC-BjGop1dQoyqM3wSkp",
            "tipo": "clase",
            "titulo": "Clases de Medicina y Sociedad"
          },
          {
            "anio": 1,
            "area": "CyD",
            "escuela": 3,
            "id": "LFh7lUxYQUA",
            "tipo": "clase",
            "titulo": "Física: ojo y oído - Laureano Albornoz"
          },
          {
            "anio": 1,
            "area": "CyD",
            "escuela": 3,
            "id": "nnWuz8lhPtI",
            "tipo": "clase",
            "titulo": "Física: ojo y oído - Stella Bertoluzzo"
          },
          {
            "anio": 1,
            "area": "CyD",
            "escuela": 3,
            "id": "TfO6g8sBiIw",
            "tipo": "clase",
            "titulo": "Física: UP 9 - Stella Bertoluzzo"
          },
          {
            "anio": 1,
            "area": "CyD",
            "escuela": 3,
            "id": "nVNjLP-_rfs",
            "tipo": "clase",
            "titulo": "Histología: Tejidos - Georgina Rizzi"
          },
          {
            "anio": 1,
            "area": "CyD",
            "escuela": 3,
            "id": "rnE2wg-TdtQ",
            "tipo": "clase",
            "titulo": "Integrador de UP4 - Maximiliano Gatti"
          },
          {
            "anio": 1,
            "area": "CyD",
            "escuela": 3,
            "id": "634zo8Hpdig",
            "tipo": "clase",
            "titulo": "Integrador interactivo - Fernanda Troiano"
          },
          {
            "anio": 1,
            "area": "CyD",
            "escuela": 3,
            "id": "Dr1642vtOzQ",
            "tipo": "clase",
            "titulo": "Integrador por UP - Maximiliano Gatti"
          },
          {
            "anio": 1,
            "area": "Nutricion",
            "escuela": 3,
            "id": "kcpDM3ixrDI",
            "tipo": "clase",
            "titulo": "Fisiología: Regulación de la Glicemia - Pablo Arias"
          },
          {
            "anio": 1,
            "area": "Nutricion",
            "escuela": 3,
            "id": "_Or3QFJWXCg",
            "tipo": "clase",
            "titulo": "Anatomía: Tubo Digestivo - Eugenia Cabral"
          },
          {
            "anio": 1,
            "area": "Nutricion",
            "escuela": 3,
            "id": "ebSHHFH7xAw",
            "tipo": "clase",
            "titulo": "Fisiología: UP 2, 3 y 4 - Pablo Arias"
          },
          {
            "anio": 1,
            "area": "Nutricion",
            "escuela": 3,
            "id": "Vs3mad7yHQY",
            "tipo": "clase",
            "titulo": "Fisiología: UP 6 y 7 - Pablo Arias"
          },
          {
            "anio": 1,
            "area": "Nutricion",
            "escuela": 3,
            "id": "5DDKsUOKYB0",
            "tipo": "clase",
            "titulo": "Introducción UP1 - CECM"
          },
          {
            "anio": 1,
            "area": "Nutricion",
            "escuela": 3,
            "id": "8CaU1IJBDk4",
            "tipo": "clase",
            "titulo": "Histología: Integrador - Georgina Rizzi"
          },
          {
            "anio": 1,
            "area": "Nutricion",
            "escuela": 3,
            "id": "m23adT296JA",
            "tipo": "clase",
            "titulo": "Integrador por UP I - Maximiliano Gatti"
          },
          {
            "anio": 1,
            "area": "Nutricion",
            "escuela": 3,
            "id": "m23adT296JA",
            "tipo": "clase",
            "titulo": "Integrador por UP II - Maximiliano Gatti"
          },
          {
            "anio": 1,
            "area": "Nutricion",
            "escuela": 3,
            "id": "WLAcDq5o6dg",
            "tipo": "clase",
            "titulo": "Integrador por UP - Fernanda Troiano"
          },
          {
            "anio": 1,
            "area": "Nutricion",
            "escuela": 3,
            "id": "v9yGInL-rbQ",
            "tipo": "clase",
            "titulo": "Fisiología: Páncreas endócrino y Regulación de la ingesta - Pablo Arias"
          },
          {
            "anio": 1,
            "area": "Nutricion",
            "escuela": 3,
            "id": "gTNT1nDc5lI",
            "tipo": "clase",
            "titulo": "Física: Termodinamia - Stella Bertoluzzo"
          },
          {
            "anio": 1,
            "area": "Nutricion",
            "escuela": 3,
            "id": "s7TA0opGgCQ",
            "tipo": "clase",
            "titulo": "Fisiología: generalidades y motilidad - Pablo Arias"
          },
          {
            "anio": 1,
            "area": "Nutricion",
            "escuela": 3,
            "id": "UIxKBxQZRks",
            "tipo": "clase",
            "titulo": "Histología: tubo digestivo y glándulas anexas I - Matías Fusini"
          },
          {
            "anio": 3,
            "area": "Defensa",
            "escuela": 3,
            "id": "ryuAchzQzEQ",
            "tipo": "clase",
            "titulo": "Semiología: repaso parte I - Belén Montenegro"
          },
          {
            "anio": 3,
            "area": "Defensa",
            "escuela": 3,
            "id": "c-3dt1larhE",
            "tipo": "clase",
            "titulo": "Semiología: repaso parte II - Belén Montenegro"
          },
          {
            "anio": 3,
            "area": "Defensa",
            "escuela": 3,
            "id": "McavzvHOGsU",
            "tipo": "clase",
            "titulo": "Inmunología: parte I - Leo Gabarela"
          },
          {
            "anio": 3,
            "area": "Defensa",
            "escuela": 3,
            "id": "jChcD49Rp90",
            "tipo": "clase",
            "titulo": "Inmunología: parte II - Leo Gabarela"
          },
          {
            "anio": 3,
            "area": "Defensa",
            "escuela": 3,
            "id": "F0CZTQtQuWc",
            "tipo": "clase",
            "titulo": "Inmunología: parte III - Leo Gabarela"
          },
          {
            "anio": 3,
            "area": "Defensa",
            "escuela": 3,
            "id": "kZ3jO-V--nA",
            "tipo": "clase",
            "titulo": "Inmunología: parte IV - Leo Gabarela"
          },
          {
            "anio": 3,
            "area": "Defensa",
            "escuela": 3,
            "id": "JAYmwxoMdOE",
            "tipo": "clase",
            "titulo": "Inmunología: parte V - Leo Gabarela"
          },
          {
            "anio": 3,
            "area": "Defensa",
            "escuela": 3,
            "id": "CGAJNYumnHI",
            "tipo": "clase",
            "titulo": "Inmunología: parte VI - Leo Gabarela"
          },
          {
            "anio": 3,
            "area": "Defensa",
            "escuela": 3,
            "id": "NmlWUMlzQfI",
            "tipo": "clase",
            "titulo": "Inmunología: parte VII - Leo Gabarela"
          },
          {
            "anio": 3,
            "area": "Defensa",
            "escuela": 3,
            "id": "xJHpC1KiILg",
            "tipo": "clase",
            "titulo": "Inmunología: Órganos Linfoides y Recirculación Linfocitaria"
          },
          {
            "anio": 3,
            "area": "Defensa",
            "escuela": 3,
            "id": "q9iqnEARuiE",
            "tipo": "clase",
            "titulo": "Farmacología: Antibióticos - Pablo Lapalma"
          },
          {
            "anio": 3,
            "area": "Defensa",
            "escuela": 3,
            "id": "-Gyo2l24gUA",
            "tipo": "clase",
            "titulo": "Inmunología: Inmunidad innata - Ariana Diaz"
          },
          {
            "anio": 3,
            "area": "Defensa",
            "escuela": 3,
            "id": "RFZTIrLm3dE",
            "tipo": "clase",
            "titulo": "Inmunología: respuesta mediada por linf T - Ariana Diaz"
          },
          {
            "anio": 3,
            "area": "Defensa",
            "escuela": 3,
            "id": "IyQICGRvkIM",
            "tipo": "clase",
            "titulo": "Sistema inmune - Jorge Molinas"
          },
          {
            "anio": 3,
            "area": "Defensa",
            "escuela": 3,
            "id": "8KeRu47pQSk",
            "tipo": "clase",
            "titulo": "Inmunología: Respuesta humoral - Luciano D'Attilio"
          },
          {
            "anio": 3,
            "area": "Defensa",
            "escuela": 3,
            "id": "vhO8g-NB2sA",
            "tipo": "clase",
            "titulo": "Inmunología: vacunas - Luciano D'Attilio"
          },
          {
            "anio": 3,
            "area": "Defensa",
            "escuela": 3,
            "id": "nAryMLmHHS0",
            "tipo": "clase",
            "titulo": "Inmunología: respuesta antitumoral - Ma. Luisa Bay"
          },
          {
            "anio": 3,
            "area": "Defensa",
            "escuela": 3,
            "id": "HJCP5L0JjSw",
            "tipo": "clase",
            "titulo": "Integrador por UP - Diego Ainsuain"
          },
          {
            "anio": 3,
            "area": "Defensa",
            "escuela": 3,
            "id": "RfiKoqnQ8n8",
            "tipo": "clase",
            "titulo": "Inmunología: respuesta antiinfecciosa - Ma. Luisa Bay"
          },
          {
            "anio": 3,
            "area": "Defensa",
            "escuela": 3,
            "id": "zyK9e9PnBbs",
            "tipo": "clase",
            "titulo": "Integrador por UP 1 a 4 - Diego Ainsuain"
          },
          {
            "anio": 3,
            "area": "Defensa",
            "escuela": 3,
            "id": "3Wz9bgmijZM",
            "tipo": "clase",
            "titulo": "Semiología: repaso integrador - Belén Montenegro"
          },
          {
            "anio": 3,
            "area": "Defensa",
            "escuela": 3,
            "id": "MQGvqne97OQ",
            "tipo": "clase",
            "titulo": "Semiología: Smes respiratorios y Abdomen Agudo - Gabriela Garrido"
          },
          {
            "anio": 3,
            "area": "Defensa",
            "escuela": 3,
            "id": "bUsQHYsoluE",
            "tipo": "clase",
            "titulo": "Semiología: integrador UP 7 y 8 - Jerónimo Ainsuain"
          },
          {
            "anio": 5,
            "area": "Cirugia",
            "escuela": 3,
            "id": "YeBJj21YTCk",
            "tipo": "clase",
            "titulo": "Cirugía: Abdomen Agudo - Dr. Gavosto"
          },
          {
            "anio": 5,
            "area": "Cirugia",
            "escuela": 3,
            "id": "kftM4ZASDIg",
            "tipo": "clase",
            "titulo": "Cirugía: consulta (I) - Dr. Gavosto"
          },
          {
            "anio": 5,
            "area": "Cirugia",
            "escuela": 3,
            "id": "kU3vHbWf-DM",
            "tipo": "clase",
            "titulo": "Cirugía: consulta (II) - Dr. Gavosto"
          },
          {
            "anio": 5,
            "area": "Cirugia",
            "escuela": 3,
            "id": "GEmLIOAvlXk",
            "tipo": "clase",
            "titulo": "Oftalmología: repaso - Dr. Echevarría"
          },
          {
            "anio": 5,
            "area": "Cirugia",
            "escuela": 3,
            "id": "wvQtN0AFWzk",
            "tipo": "clase",
            "titulo": "Oftalmología: repaso - Dra. Martino"
          },
          {
            "anio": 5,
            "area": "Cirugia",
            "escuela": 3,
            "id": "3nqB7frLtrw",
            "tipo": "clase",
            "titulo": "ORL: consulta - Dr. Bassagaisteguy"
          },
          {
            "anio": 5,
            "area": "Cirugia",
            "escuela": 3,
            "id": "Rpd2fIj8HcI",
            "tipo": "clase",
            "titulo": "ORL: consulta - Dr. Fugugt"
          },
          {
            "anio": 5,
            "area": "Cirugia",
            "escuela": 3,
            "id": "r1a4d3nXy3w",
            "tipo": "clase",
            "titulo": "ORL: consulta - Dra. De Pauli y Dr. Fabre"
          },
          {
            "anio": 5,
            "area": "Cirugia",
            "escuela": 3,
            "id": "7b5sYjsTfI0",
            "tipo": "clase",
            "titulo": "Traumatología: consulta (I) - Dr. Lera"
          },
          {
            "anio": 5,
            "area": "Cirugia",
            "escuela": 3,
            "id": "NHsV_67_P4s",
            "tipo": "clase",
            "titulo": "Traumatología: consulta (II) - Dr. Lera"
          },
          {
            "anio": 5,
            "area": "Cirugia",
            "escuela": 3,
            "id": "A9t3RrhHiy0",
            "tipo": "clase",
            "titulo": "Traumatología: Dolor articular - Dr. Lera"
          },
          {
            "anio": 5,
            "area": "Cirugia",
            "escuela": 3,
            "id": "3M3FoYhLmJY",
            "tipo": "clase",
            "titulo": "Traumatología: Fracturas por regiones - Dr. Lera"
          },
          {
            "anio": 5,
            "area": "Cirugia",
            "escuela": 3,
            "id": "9SoFSSxaDCs",
            "tipo": "clase",
            "titulo": "Traumatología: Generalidades de fracturas - Dr. Lera"
          },
          {
            "anio": 5,
            "area": "Cirugia",
            "escuela": 3,
            "id": "gdaYZOvyAqY",
            "tipo": "clase",
            "titulo": "Traumatología: Patología de Columna - Dr. Ferrer"
          },
          {
            "anio": 5,
            "area": "Pediatria",
            "escuela": 3,
            "id": "UDmptxYeff4",
            "tipo": "clase",
            "titulo": "Anemias en la infancia "
          },
          {
            "anio": 5,
            "area": "Pediatria",
            "escuela": 3,
            "id": "UDmptxYeff4",
            "tipo": "clase",
            "titulo": "Lactante sibilante - Dr. Gabutti"
          },
          {
            "anio": 4,
            "area": "Gineco-Obstetricia",
            "escuela": 3,
            "id": "-38MO6pNfco",
            "tipo": "clase",
            "titulo": "Consulta: Dr. Bosco (I) - Obstetricia"
          },
          {
            "anio": 4,
            "area": "Gineco-Obstetricia",
            "escuela": 3,
            "id": "FNK21vUQ65A",
            "tipo": "clase",
            "titulo": "Consulta: Dr. Bosco (II) - Obstetricia"
          },
          {
            "anio": 4,
            "area": "Gineco-Obstetricia",
            "escuela": 3,
            "id": "34lAXCjw4nI",
            "tipo": "clase",
            "titulo": "Consulta: Dr. Delgado - Ginecología (I)"
          },
          {
            "anio": 4,
            "area": "Gineco-Obstetricia",
            "escuela": 3,
            "id": "R_Ns-k-S2w0",
            "tipo": "clase",
            "titulo": "Consulta: Dr. Delgado - Ginecología (II)"
          },
          {
            "anio": 4,
            "area": "Gineco-Obstetricia",
            "escuela": 3,
            "id": "Bk2fjVCHzqg",
            "tipo": "clase",
            "titulo": "Consulta: Dr. Delgado - Ginecología (III)"
          },
          {
            "anio": 4,
            "area": "Gineco-Obstetricia",
            "escuela": 3,
            "id": "mRD-MYSZ9gw",
            "tipo": "clase",
            "titulo": "Ginecología: Patología mamaria - Dr. Tabares"
          },
          {
            "anio": 4,
            "area": "Gineco-Obstetricia",
            "escuela": 3,
            "id": "uqe_Jy7buok",
            "tipo": "clase",
            "titulo": "Obstetricia: evaluación salud fetal, eco doppler, lactancia - Dr. Ponce"
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "fjdtEk0e050",
            "tipo": "clase",
            "titulo": "Caso clínico: Crisis de ansiedad - Dr. Touñez"
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "E0BcPhuICDw",
            "tipo": "clase",
            "titulo": "Caso clínico: dolor abdominal (I)"
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "ky1bibRzolA",
            "tipo": "clase",
            "titulo": "Caso clínico: dolor abdominal (II)"
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "FPIgFLOWrZw",
            "tipo": "clase",
            "titulo": "Caso clínico: Hepatopatía - Dr. Bessone"
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "sTREx4D2JaA",
            "tipo": "clase",
            "titulo": "Caso clínico: HIV - SIDA - Dr. Adriani"
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "CNAYKYkgUd0",
            "tipo": "clase",
            "titulo": "Caso clínico: Insuficiencia Renal - Ruben Cavoduro"
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "mcen2D_FRig",
            "tipo": "clase",
            "titulo": "Caso clínico: Meningoencefalitis"
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "TpK6drhcA_0",
            "tipo": "clase",
            "titulo": "Caso clínico: Sme confusional agudo - Dra. Kippes"
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "eyXiWYS7ozA",
            "tipo": "clase",
            "titulo": "Consulta Dr. Abdala - Reumatología"
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "0n4ILOcP93I",
            "tipo": "clase",
            "titulo": "Consulta Dr. Berlanda - Psiquiatría: Ansiedad y depresión"
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "LnqLjNZdGTA",
            "tipo": "clase",
            "titulo": "Consulta Dr. Bessone - Hepato: Cirrosis"
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "USKK-JK8wXI",
            "tipo": "clase",
            "titulo": "Consulta Dr. Kilstein (I)"
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "9jzTM2Qlx9Q",
            "tipo": "clase",
            "titulo": "Consulta Dr. Kilstein (II)"
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "Dqqojl0PyZA",
            "tipo": "clase",
            "titulo": "Consulta Dr. Lerman - ATB"
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "Rm9FwgNkOkY",
            "tipo": "clase",
            "titulo": "Consulta Dr. Lerman (I)"
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "98Dsi2nfnmE",
            "tipo": "clase",
            "titulo": "Consulta Dr. Lerman (II)"
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "nCsjhNllnmk",
            "tipo": "clase",
            "titulo": "Consulta Dr. Lerman (III)"
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "4Jm1fR6xTOY",
            "tipo": "clase",
            "titulo": "Consulta Dr. Parodi - HTA, dislipemias, RCV, TEP"
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "K8LZ_1EUu80",
            "tipo": "clase",
            "titulo": "Consulta Dra. Lagrutta "
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "7k-YQjNb9Cs",
            "tipo": "clase",
            "titulo": "Consulta Dra. Molteni - Dermatología"
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "YDnjpH83cNk",
            "tipo": "clase",
            "titulo": "Consulta Dra. Negro - Nefrología: Sme. Nefrítico y Nefrótico"
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "1_i8tm3K5X8",
            "tipo": "clase",
            "titulo": "Consulta Dra. Quartara "
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "Fp-s2kAOWs0",
            "tipo": "clase",
            "titulo": "Integrador: Anemias - Dr. Braidot"
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "O8kI82x0D8o",
            "tipo": "clase",
            "titulo": "Integrador: Cirrosis y desorientación - Dra. Montenegro"
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "druT2PsYsSQ",
            "tipo": "clase",
            "titulo": "Integrador: Dolor Torácico - Belén Montenegro"
          },
          {
            "anio": 5,
            "area": "Clinica",
            "escuela": 3,
            "id": "wah4P61gQ14",
            "tipo": "clase",
            "titulo": "Integrador: Enf. tromboembólica - Dra. Montenegro"
          },
          {
            "anio": 0,
            "area": "Cursos",
            "escuela": 1,
            "id": "GknfVCEg0Xo",
            "tipo": "clase",
            "titulo": "Capacitación de RCP"
          },
          {
            "anio": 0,
            "area": "Cursos",
            "escuela": 1,
            "id": "s1ncFkoAXfE",
            "tipo": "clase",
            "titulo": "El rol de la ENFERMERÍA y el CUIDADO a lo largo de la HISTORIA - Alicia Reynoso"
          },
          {
            "anio": 0,
            "area": "Grupo Estudio",
            "escuela": 1,
            "id": "Ms2x-sKRHro",
            "tipo": "clase",
            "titulo": "2° año - Construcción del Saber en Salud II"
          },
          {
            "anio": 0,
            "area": "Grupo Estudio",
            "escuela": 1,
            "id": "NbovNVeYKeU",
            "tipo": "clase",
            "titulo": "1° año - Salud Enfermedad Atención 22-3-22"
          },
          {
            "anio": 0,
            "area": "Grupo Estudio",
            "escuela": 1,
            "id": "pd-OWlL00lI",
            "tipo": "clase",
            "titulo": "1° año - Construcción del Saber en Salud I"
          },
          {
            "anio": 0,
            "area": "Grupo Estudio",
            "escuela": 1,
            "id": "7F4UjWHl6mo",
            "tipo": "clase",
            "titulo": "4° año - Adulto Crítico 3-2-22"
          },
          {
            "anio": 0,
            "area": "Grupo Estudio",
            "escuela": 1,
            "id": "N8a4Zf1SHfA",
            "tipo": "clase",
            "titulo": "1° año - Biología: Sistema Nervioso"
          },
          {
            "anio": 0,
            "area": "Grupo Estudio",
            "escuela": 1,
            "id": "5nxiyHz63As",
            "tipo": "clase",
            "titulo": "2° año - Farmacología"
          },
          {
            "anio": 0,
            "area": "Grupo Estudio",
            "escuela": 1,
            "id": "2PJBj_EHDBw",
            "tipo": "clase",
            "titulo": "2° año - Microbiología"
          },
          {
            "anio": 0,
            "area": "Grupo Estudio",
            "escuela": 1,
            "id": "jKNuMEVLF2g",
            "tipo": "clase",
            "titulo": "1° año - Nutrición y Dietoterapia (1)"
          },
          {
            "anio": 0,
            "area": "Grupo Estudio",
            "escuela": 1,
            "id": "PbEoEKlP2-0",
            "tipo": "clase",
            "titulo": "1° año - Nutrición y Dietoterapia (2)"
          },
          {
            "anio": 0,
            "area": "Grupo Estudio",
            "escuela": 1,
            "id": "snBcM5YTaM4",
            "tipo": "clase",
            "titulo": "1° año - Biología: Cardiovascular y Respiratorio"
          },
          {
            "anio": 0,
            "area": "Grupo Estudio",
            "escuela": 1,
            "id": "9DTlo21gEwI",
            "tipo": "clase",
            "titulo": "1° año - Construcción de la Subjetividad"
          },
          {
            "anio": 0,
            "area": "Grupo Estudio",
            "escuela": 1,
            "id": "rM3LE4Qv5tQ",
            "tipo": "clase",
            "titulo": "2° año - Práctico: Trayecto Procedimental II"
          },
          {
            "anio": 0,
            "area": "Grupo Estudio",
            "escuela": 1,
            "id": "t5V_d_yNTEA",
            "tipo": "clase",
            "titulo": "1° año - Biología: Sistema Esquelético"
          },
          {
            "anio": 0,
            "area": "Grupo Estudio",
            "escuela": 1,
            "id": "WQeHdfTQPyc",
            "tipo": "clase",
            "titulo": "1° año - Bases Disciplinares"
          },
          {
            "anio": 0,
            "area": "Grupo Estudio",
            "escuela": 1,
            "id": "fJvuGfRXUWE",
            "tipo": "clase",
            "titulo": "1° año - Práctico: Trayecto Procedimental I y Tecnología"
          },
          {
            "anio": 0,
            "area": "Grupo Estudio",
            "escuela": 1,
            "id": "5gf7RxykDiY",
            "tipo": "clase",
            "titulo": "1° año - Práctico: Trayecto Procedimental I \"Examen Físico\""
          },
          {
            "anio": 0,
            "area": "Grupo Estudio",
            "escuela": 1,
            "id": "9q1VqcsrB_A",
            "tipo": "clase",
            "titulo": "3° año - Cuidado al Adulto Joven y Maduro"
          },
          {
            "anio": 0,
            "area": "Grupo Estudio",
            "escuela": 1,
            "id": "A7l2Z07lhjc",
            "tipo": "clase",
            "titulo": "3° año - Cuidado al Adulto Joven y Maduro: Traumatismo y TEC"
          },
          {
            "anio": 0,
            "area": "Grupo Estudio",
            "escuela": 1,
            "id": "3YLkPlk1vvA",
            "tipo": "clase",
            "titulo": "1° año - Biología: Célula"
          },
          {
            "anio": 0,
            "area": "Grupo Estudio",
            "escuela": 1,
            "id": "FG6BpiksIx4",
            "tipo": "clase",
            "titulo": "1° año - Tecnologías en Enfermería"
          },
          {
            "anio": 0,
            "area": "Grupo Estudio",
            "escuela": 1,
            "id": "FRwIoKdua9M",
            "tipo": "clase",
            "titulo": "2° año - Microbiología: cómo estudiarla?"
          },
          {
            "anio": 0,
            "area": "Grupo Estudio",
            "escuela": 1,
            "id": "QdF6IiUrg7E",
            "tipo": "clase",
            "titulo": "2° año - Cuidado del Enfermero al Niño: cómo estudiarla?"
          },
          {
            "anio": 0,
            "area": "Clase de repaso",
            "escuela": 1,
            "id": "x7XsP0RwgCs",
            "tipo": "clase",
            "titulo": "1° año - Biología - docentes de la Cátedra"
          },
          {
            "anio": 0,
            "area": "Clase de repaso",
            "escuela": 1,
            "id": "8AaFfsOOR1c",
            "tipo": "clase",
            "titulo": "1° año - Biología: Sistema Linfático - Gastón Ramirez"
          },
          {
            "anio": 0,
            "area": "Clase de repaso",
            "escuela": 1,
            "id": "bgEr2gDWD0M",
            "tipo": "clase",
            "titulo": "2° año - Farmacología - Prof. Darío Castro"
          },
          {
            "anio": 0,
            "area": "Clase de repaso",
            "escuela": 1,
            "id": "lWhbtmYem60",
            "tipo": "clase",
            "titulo": "1° año - Construcción de la Subjetividad - Julieta Serrano"
          },
          {
            "anio": 0,
            "area": "Clase de repaso",
            "escuela": 1,
            "id": "LCYrbiO5Cbk",
            "tipo": "clase",
            "titulo": "1° año - Bases disciplinares - Ana Muller"
          },
          {
            "anio": 0,
            "area": "Clase de repaso",
            "escuela": 1,
            "id": "k56wDfIMgqU",
            "tipo": "clase",
            "titulo": "3° año - Metodología de la Investigación: cómo preparar el trabajo - Euge Cabral"
          },
          {
            "anio": 0,
            "area": "Clase de repaso",
            "escuela": 1,
            "id": "SQcPDFKSFMk",
            "tipo": "clase",
            "titulo": "Taller de normas APA"
          },
          {
            "anio": 0,
            "area": "Clase de repaso",
            "escuela": 1,
            "id": "bZB3l06ssuA",
            "tipo": "clase",
            "titulo": "1° año - Construcción de la Subjetividad - Ariel Monti"
          },
          {
            "anio": 0,
            "area": "Cursos",
            "escuela": 1,
            "id": "BlbFDTDAdvQ",
            "tipo": "clase",
            "titulo": "Clase 1: Organización de los servicios de Neo"
          },
          {
            "anio": 0,
            "area": "Cursos",
            "escuela": 1,
            "id": "8_AeCB_1unM",
            "tipo": "clase",
            "titulo": "Clase 2: Cuidados de la piel del recién nacido"
          },
          {
            "anio": 0,
            "area": "Cursos",
            "escuela": 1,
            "id": "UC4ZZGPE8Xo",
            "tipo": "clase",
            "titulo": "Clase 3: Respiratorio"
          },
          {
            "anio": 0,
            "area": "Cursos",
            "escuela": 1,
            "id": "oEkZWHnsAhg",
            "tipo": "clase",
            "titulo": "Clase 4: Neurodesarrollo"
          },
          {
            "anio": 0,
            "area": "Cursos",
            "escuela": 1,
            "id": "x4QGTfB00VU",
            "tipo": "clase",
            "titulo": "Cursos Signos Vitales: 1° Clase"
          },
          {
            "anio": 0,
            "area": "Cursos",
            "escuela": 1,
            "id": "gdjLak-Dwew",
            "tipo": "clase",
            "titulo": "Cursos Signos Vitales: 2° Clase"
          },
          {
            "anio": 0,
            "area": "Cursos",
            "escuela": 1,
            "id": "LPRs1L8knfY",
            "tipo": "clase",
            "titulo": "Cursos de Extracción de Sangre:"
          },
          {
            "anio": 0,
            "area": "Simulacro de examen",
            "escuela": 1,
            "id": "BGmZdbtK9lE",
            "tipo": "clase",
            "titulo": "1° año - Bases Disciplinares"
          },
          {
            "anio": 0,
            "area": "Simulacro de examen",
            "escuela": 1,
            "id": "SKRhsjtJzPY",
            "tipo": "clase",
            "titulo": "1° año - Salud Enfermedad Atención"
          },
          {
            "anio": 0,
            "area": "Simulacro de examen",
            "escuela": 1,
            "id": "We_-W3OwjQ4",
            "tipo": "clase",
            "titulo": "1° año - Biología (1)"
          },
          {
            "anio": 0,
            "area": "Simulacro de examen",
            "escuela": 1,
            "id": "IcxptMQ7LFU",
            "tipo": "clase",
            "titulo": "1° año - Biología (2)"
          },
          {
            "anio": 0,
            "area": "Simulacro de examen",
            "escuela": 1,
            "id": "ZP26ZZbsoac",
            "tipo": "clase",
            "titulo": "Cómo preparar mi primer examen final?"
          },
          {
            "anio": 1,
            "area": "Módulo de Introducción al Cursado",
            "escuela": 1,
            "id": "d78BMUPL0XU",
            "tipo": "clase",
            "titulo": "Biología: cómo estudiarla?"
          },
          {
            "anio": 1,
            "area": "Módulo de Introducción al Cursado",
            "escuela": 1,
            "id": "2uaDhe9v8pQ",
            "tipo": "clase",
            "titulo": "Trayecto Procedimental I - introducción a la materia"
          },
          {
            "anio": 1,
            "area": "Módulo de Introducción al Cursado",
            "escuela": 1,
            "id": "-5IiHWJzmO0",
            "tipo": "clase",
            "titulo": "Salud Enfermedad Atención - introducción a la materia"
          },
          {
            "anio": 1,
            "area": "Módulo de Introducción al Cursado",
            "escuela": 1,
            "id": "z2XvUSmwxq0",
            "tipo": "clase",
            "titulo": "Biología - introducción a la materia"
          },
          {
            "anio": 1,
            "area": "Módulo de Introducción al Cursado",
            "escuela": 1,
            "id": "9a8cWwDJfSs",
            "tipo": "clase",
            "titulo": "Bases Disciplinares - introducción a la materia"
          },
          {
            "anio": 1,
            "area": "Módulo de Introducción al Cursado",
            "escuela": 1,
            "id": "paM1JWZLh-0",
            "tipo": "clase",
            "titulo": "Bases disciplinares: cómo estudiarla?"
          },
          {
            "anio": 1,
            "area": "Módulo de Introducción al Cursado",
            "escuela": 1,
            "id": "0Zbp9f7RBxM",
            "tipo": "clase",
            "titulo": "Salud Enfermedad Atención: cómo estudiarla?"
          },
          {
            "anio": 0,
            "area": "Cursos",
            "escuela": 2,
            "id": "yme3-Dks7gM",
            "tipo": "clase",
            "titulo": "Jornada: Estimulación Temprana"
          },
          {
            "anio": 0,
            "area": "Cursos",
            "escuela": 2,
            "id": "YUo_Pftdd30",
            "tipo": "clase",
            "titulo": "Charla: La ciencia en el arte del canto - Soledad Sacheri"
          },
          {
            "anio": 0,
            "area": "Cursos",
            "escuela": 2,
            "id": "XuTL5j_ND8o",
            "tipo": "clase",
            "titulo": "Charla: Fonoaudiología y Salud Pública en pandemia - Micaela Fagioli"
          },
          {
            "anio": 0,
            "area": "Cursos",
            "escuela": 2,
            "id": "UsidJEz4Dks",
            "tipo": "clase",
            "titulo": "Charla: El cuidado de las infancias en aislamiento - Fernanda Felice"
          },
          {
            "anio": 0,
            "area": "Cursos",
            "escuela": 2,
            "id": "vsWm6I-62I0",
            "tipo": "clase",
            "titulo": "Curso Disfagia: un abordaje integral - Lucía Gargallo"
          },
          {
            "anio": 0,
            "area": "Cursos",
            "escuela": 2,
            "id": "THK0kmaaoiE",
            "tipo": "clase",
            "titulo": "Curso Disfagia: bases patológicas - Guadalupe Bruera"
          },
          {
            "anio": 0,
            "area": "Cursos",
            "escuela": 2,
            "id": "a4BSDkstDrY",
            "tipo": "clase",
            "titulo": "Charla: En tiempos donde los diagnósticos rotulan - Yanina Romani"
          },
          {
            "anio": 0,
            "area": "Cursos",
            "escuela": 2,
            "id": "ivrhYvo0sk0",
            "tipo": "clase",
            "titulo": "Charla: Comunicación intrauterina - @mamayfono.ok"
          },
          {
            "anio": 0,
            "area": "Teoricos",
            "escuela": 2,
            "id": "o4kA2dvQi8s",
            "tipo": "clase",
            "titulo": "Lenguaje y Aprendizaje patológico - Fernanda Felice"
          },
          {
            "anio": 0,
            "area": "Teoricos",
            "escuela": 2,
            "id": "JKlGge49E0k",
            "tipo": "clase",
            "titulo": "Cs. Biológicas: Generalidades de anatomía, célula, tejidos y fosas nasales - Cristian Kuchen"
          },
          {
            "anio": 0,
            "area": "Teoricos",
            "escuela": 2,
            "id": "GLsIevb7HVs",
            "tipo": "clase",
            "titulo": "Cs. Biológicas: Vías aéreas inferiores - Cristian Kuchen"
          },
          {
            "anio": 0,
            "area": "Teoricos",
            "escuela": 2,
            "id": "HuXU8g5YErk",
            "tipo": "clase",
            "titulo": "Cs. Biológicas: Tórax óseo - Cristian Kuchen"
          },
          {
            "anio": 0,
            "area": "Teoricos",
            "escuela": 2,
            "id": "FM39_Wxtgz0",
            "tipo": "clase",
            "titulo": "Cs. Biológicas: célula - Cristian Kuchen"
          },
          {
            "anio": 0,
            "area": "Teoricos",
            "escuela": 2,
            "id": "fSqAvkIGhCg",
            "tipo": "clase",
            "titulo": "Cs. Biológicas: tejidos - Cristian Kuchen"
          },
          {
            "titulo": "Enfermedades Infecciosas",
            "id": "PLVHL_sCmXigEoBKB71QKKpbUIB_5XvZBk",
            "tipo": "curso",
            "carrera": 3
          },
          {
            "titulo": "Farmacología aplicada 2022",
            "id": "PLVHL_sCmXigHiQftAOCMI96CSeWh_2tPL",
            "tipo": "curso",
            "carrera": 3
          },
          {
            "titulo": "Enfermedades Crónicas no Transmisibles",
            "id": "PLVHL_sCmXigF7GHE1oGHZmfzTYM7TCAny",
            "tipo": "curso",
            "carrera": 3
          },
          {
            "titulo": "Anatomía por esquemas 2do año 2019",
            "id": "PLVHL_sCmXigH4s13pE95EOaSlY7es9ZFQ",
            "tipo": "curso",
            "carrera": 3
          },
          {
            "titulo": "Sistema Nervioso",
            "id": "PLVHL_sCmXigFn2colilEh0U5mtfGn6TQS",
            "tipo": "curso",
            "carrera": 3
          },
          {
            "titulo": "Anatomía de Miembros",
            "id": "PLVHL_sCmXigEvvkjIZn_lQf_eMHbSNE54",
            "tipo": "curso",
            "carrera": 3
          },
          {
            "titulo": "Traumatología",
            "id": "PLVHL_sCmXigEAbvYKFkSW-jmCZRGb32UP",
            "tipo": "curso",
            "carrera": 3
          },
          {
            "titulo": "Anato-histo-fisio de la Reproducción",
            "id": "PLVHL_sCmXigG9Jb13gH4_ZhxLvMfRGn2c",
            "tipo": "curso",
            "carrera": 3
          },
          {
            "titulo": "Electrocardiograma",
            "id": "PLVHL_sCmXigET4vRnQMq6JtPEqQZHtUoJ",
            "tipo": "curso",
            "carrera": 3
          },
          {
            "titulo": "Urgencias y Emergencias",
            "id": "PLVHL_sCmXigFsh_eMwm8ZSYeX0x3V29Om",
            "tipo": "curso",
            "carrera": 3
          },
          {
            "titulo": "Diagnóstico por imágenes",
            "id": "PLVHL_sCmXigF5elz_aJc0h8XKllC8C2RP",
            "tipo": "curso",
            "carrera": 3
          },
          {
            "titulo": "Farmacología aplicada 2021",
            "id": "PLVHL_sCmXigEBHL8Z9o2xYpTRn4F5E6pU",
            "tipo": "curso",
            "carrera": 3
          },
          {
            "titulo": "COCEM 2020",
            "id": "PLVHL_sCmXigE72q0ftU40EDBk0DL4TuOV",
            "tipo": "curso",
            "carrera": 3
          },
          {
            "titulo": "Bases de la Farmacología 2021",
            "id": "PLVHL_sCmXigG88WyjjOd7SngmfvDmz81-",
            "tipo": "curso",
            "carrera": 3
          },
          {
            "titulo": "Anatomía de Abdomen y Retroperitoneo",
            "id": "PLVHL_sCmXigHwMscdALeBSRmOkhgwUGOz",
            "tipo": "curso",
            "carrera": 3
          },
          {
            "titulo": "Bases del Sistema Nervioso Central",
            "id": "PLVHL_sCmXigE6ZdOchvpaw9Mx0YgInKf_",
            "tipo": "curso",
            "carrera": 3
          }
    ];
    datos.forEach(async (element) => {
      const docRef = await addDoc(collection(db, "videos"), element);
      console.log(docRef);
    });
    alert("Finalizado");
  }
  return <>  <button onClick={subirData}>Subir data</button> </>;
}

export default Upload;
