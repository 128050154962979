import React from 'react';
import { useParams } from 'react-router-dom';
import YouTube, { YouTubeProps } from 'react-youtube';

function VideoYoutube(props : { id? : string}) {
  const { videoId } = useParams();
  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  const opts: YouTubeProps['opts'] = {
    height: '100%',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: '0',
    },
  };

  return <YouTube  className={"h-100 min-h"} videoId={props.id || videoId} opts={opts} onReady={onPlayerReady} />;
}
export default VideoYoutube;