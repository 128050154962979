import { collection, Firestore, getDocs, query, where } from 'firebase/firestore/lite';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { VideoData } from '../classes/videos';
import ListYoutube from './common/list-youtube';
import PlayList from './common/playlist';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faM , faE, faF} from '@fortawesome/free-solid-svg-icons'

function Carreras() {
    const navigate = useNavigate();
    return <>
                <Row className='justify-content-center' style={{verticalAlign: 'bottom'}} >
                    <Col onClick={() => navigate("/escuela/3")} className="center-block">
                        <div className="icon-box colcarreras">
                            <h4><a className='verde bold'>Medicina</a></h4>
                        </div>
                    </Col>

                    <Col onClick={() => navigate("/escuela/1")} className="center-block">
                        <div className="icon-box colcarreras">
                            <h4><a className='verde bold'>Enfermeria</a></h4>
                        </div>
                    </Col>

                    <Col onClick={() => navigate("/escuela/2")} className="center-block">
                        <div className="icon-box colcarreras">
                            <h4><a className='verde bold'>Fonoaudiologia</a></h4>
                        </div>
                    </Col>
                </Row>
    </>
}
export default Carreras;