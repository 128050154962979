import React, { useEffect, useState, useMemo } from "react";
import { Col, Container, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { BsPlayFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { VideoData } from "../classes/videos";
import ListYoutube from "./common/list-youtube";
import PlayList from "./common/playlist";
import VideoYoutube from "./common/video-youtube";

function Clase(props: { videos: VideoData[] }) {
  const { videoId } = useParams();
  const [sugerencias, setSugerencias] = useState<VideoData[]>();
  const navigate = useNavigate();
  const videoData = useMemo(() => props.videos.filter(x => x.id === videoId)[0],[videoId])
  useEffect(() => {
    setSugerencias(props.videos.filter(x => x.escuela === videoData?.escuela && x.area === videoData.area && x.anio === videoData.anio));
  },[]);
  const openPlaylist = (dat: VideoData) => {
    navigate("/" + dat.tipo + "/" + dat.id);
  };
   return (
    <>
        <div className="section-title bgverde blanco bold">
          <h2 className="blanco">{videoData?.area}</h2>
        </div>
       <Container className="min-h-100">
        <div><h1 className="bold mt-4">{videoData?.titulo}</h1></div>
          <Row className="my-4 ">
            <Col className="bgverde rowVideos" lg={8} md={8} >
              <div className="h-50 p-4">
                <VideoYoutube id={videoData?.id}/>
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div className="section-title">
              <h1>Videos sugeridos</h1>
             
              </div>
            <ListGroup horizontal>
          <Container>
            {sugerencias?.slice(0,10).map((dat: VideoData) => (
              <ListGroupItem
                action
                key={dat.id}
                onClick={() => openPlaylist(dat)}
              >
                <Row>
                  <Col md={2} lg={2} className="pt-1">
                    <BsPlayFill size={20}/>
                  </Col>
                  <Col> {dat.titulo}</Col>
                </Row>
              </ListGroupItem>
            ))}
          </Container>
        </ListGroup>
            </Col>
          </Row>
        </Container>
    </>
  );
}
export default Clase;
