import { collection, Firestore, getDocs, query, where } from 'firebase/firestore/lite';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { VideoData } from '../classes/videos';
import ListYoutube from './common/list-youtube';
import PlayList from './common/playlist';


function Anio(props: { videos: VideoData[] }) {
    function onlyUnique(value: any, index: any, self: string | any[]) {
        return self.indexOf(value) === index;
    }
    const unique = props.videos.map(x => x.area).filter(onlyUnique).sort();

    return <>
        <Row>
        {
            unique.map((val) =>(
            <>
                <Col align="center">
                    <h3 className='area-title center verde bold'>{val}</h3>
                    <ListYoutube data={props.videos.filter(x => x.area === val).sort((x,y) => x.titulo < y.titulo ? -1 : 1)} image={false} fulldata={false}/>
                </Col>
            </>))
        }
        </Row>
        
        
    </>
}
export default Anio;