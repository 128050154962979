import { Router } from "@remix-run/router";
import {
  collection,
  Firestore,
  getDocs,
  query,
  where,
} from "firebase/firestore/lite";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { VideoData } from "../../classes/videos";
import Carreras from "../carreras";
import ListYoutube from "../common/list-youtube";
import PlayList from "../common/playlist";
import Curso from "../curso";
import UltimosCurso from "../ultimoscursos";
import "./home.css";
import { InstagramEmbed } from "react-social-media-embed";
import { Tooltip } from "@chakra-ui/react";
import { FaInstagram, FaYoutube } from "react-icons/fa";
import { BsPrinterFill } from "react-icons/bs";
function Home(props: { cursos: VideoData[]; insta: string[] }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  return (
    <>
      <section id="hero" className="d-flex align-items-center">
        <Container
          className="container position-relative"
          data-aos="fade-up"
          data-aos-delay="500"
          style={{ marginTop: "10%" }}
        >
          <h1 style={{ textAlign: "center" }}>
            Por Educacion publica de Calidad
          </h1>
          <Carreras />
        </Container>
      </section>
      <section className="pt-1">
        <Container id="cursos">
          <Row>
            <div className="section-title">
              <h2 className="verde bold">Cursos</h2>
            </div>
            <UltimosCurso
              cursos={props.cursos.sort(() => 0.5 - Math.random())}
            />
          </Row>
          <Row className="justify-content-center pt-4">
            <Col className="d-flex justify-content-center">
              <Button className="btn-todos" onClick={() => navigate("/cursos")}>
                Ver todos los cursos
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
      <section style={{ backgroundColor: "#CCC" }}>
        <Container>
          <Row>
            <div className="section-title">
              <h2 className=" bold">SEGUINOS</h2>
            </div>
          </Row>
          <Row style={{marginBottom: '4rem'}}>
            <Col md={{ span: 4, offset: 4 }} style={{display:'flex', justifyContent:'center'}}>
              <a
                
                href="https://www.instagram.com/alde.cienciasmedicas/"
                target="_blank"
                style={{marginLeft: '1rem', marginRight: '1rem'}}
              >
                <Tooltip label="Instagram">
                  <span>
                    <FaInstagram size={70}/>
                  </span>
                </Tooltip>
              </a>
              <a
                
                href="https://www.youtube.com/@aldefcm"
                target="_blank"
                style={{marginLeft: '1rem', marginRight: '1rem'}}
              >
                <Tooltip label="Youtube">
                  <span>
                    <FaYoutube size={70}/>
                  </span>
                </Tooltip>
              </a>
              <a
                href="https://materialdeestudiocecm.net/"
                target="_blank"
                style={{marginLeft: '1rem', marginRight: '1rem'}}
              >
                <Tooltip label="Secretaria de material de estudio">
                  <span>
                    <BsPrinterFill size={70}/>
                  </span>
                </Tooltip>
              </a>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              <InstagramEmbed url={props.insta[0]} width={328} captioned />
            </Col>
            <Col className="d-flex justify-content-center">
              <InstagramEmbed url={props.insta[1]} width={328} captioned />
            </Col>
            <Col className="d-flex justify-content-center">
              <InstagramEmbed url={props.insta[2]} width={328} captioned />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
export default Home;
