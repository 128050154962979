import { Router } from '@remix-run/router';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FaInstagram , FaYoutube , FaSearch} from "react-icons/fa";
import { BsPrinterFill } from "react-icons/bs";
import { VideoData } from '../../classes/videos';
import Logo from '../../assets/logo.png'
import { Tooltip } from '@chakra-ui/react';
import { Col, Row } from 'react-bootstrap';


function Navigation(props: { router: Router }) {
  const [values,setValues] = useState<string>("");
  const handleSubmit = (event : React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.router.navigate("/buscar/"+values)
}
const handleChange = (event : React.ChangeEvent<HTMLInputElement>) => {
  setValues(event.target.value);
}

  return (
    <>
    <Row className='nav-custom'>
      <Col className='d-flex justify-content-center' lg={2} onClick={() => props.router.navigate("/")}>
        <div className='pb-2 pt-1 ps-4'>
          <img src={Logo} width={240} />
        </div>
      </Col>
      <Col lg={10} className="px-0"><Navbar collapseOnSelect expand="lg" >

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">

          </Nav>
          <Nav>
        
            <Nav.Link href="#nosotros">NOSOTRXS</Nav.Link>
            <Nav.Link onClick={() => props.router.navigate("/cursos")}>CURSOS</Nav.Link>
            <Nav.Link onClick={() => props.router.navigate("/carreras")}>CARRERAS</Nav.Link>
            <Form className="d-flex ms-4 me-4" onSubmit={handleSubmit}>
            <Form.Control
              type="search"
              placeholder="Buscar"
              className="me-2"
              aria-label="Search"
              name={"search"}
              onChange={handleChange}
            />
            <Button type="submit"> <FaSearch /></Button>
          </Form>
          </Nav>
        </Navbar.Collapse>
    </Navbar>
    </Col>
    </Row>
        
    </>

  );
}

export default Navigation;