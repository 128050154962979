import { Container } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Card, Col, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { VideoData } from "../../classes/videos";
import { BsPlayFill } from "react-icons/bs";

function ListYoutube(props: { data: VideoData[]; image: boolean ; fulldata:boolean}) {
  const navigate = useNavigate();
  const openPlaylist = (dat: VideoData) => {
    navigate("/" + dat.tipo + "/" + dat.id);
  };

  return (
    <Row className="col-12">
      {!props.image ? 
        <ListGroup horizontal>
          <Container>
            {props.data.map((dat: VideoData) => (
              <ListGroupItem
                action
                key={dat.id}
                onClick={() => openPlaylist(dat)}
              >
                <Row>
                  <Col md={2} lg={2} className="pt-1">
                    <BsPlayFill size={20}/>
                  </Col>
                  <Col> {dat.titulo}</Col>
                </Row>
                {props.fulldata && <>
                  <Row>
                    <Col>Año: {dat.anio} Area: {dat.area}</Col>
                  </Row>
                </>}
              </ListGroupItem>
            ))}
          </Container>
        </ListGroup>

    :
        props.data.map((dat: VideoData) => (
          <Col key={dat.id} lg={6}>
            <Card
              style={{ backgroundImage: `url(/${dat.id}.jpg)`,backgroundSize: 'cover'}}
              onClick={() => openPlaylist(dat)}
            >
              <Card.Body>{dat.titulo}</Card.Body>
            </Card>
          </Col>
        ))}
    </Row>
  );
}
export default ListYoutube;
