import { Router } from "@remix-run/router";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import "./footer.css";
import Logo from '../../assets/logo.png'
function Footer() {
  return (
   

    <footer className="footer">
      <Container>
        <Row>
          <Col className="d-flex justify-content-center">
          <img src={Logo} width={300} />
         
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
          <h1>Comprometidxs con la educacion publica</h1>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
