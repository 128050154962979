import React, { useEffect, useState } from 'react';
import { Card, Carousel, Col, Container, ListGroup, Modal, ModalBody, Row } from 'react-bootstrap';
import { Item, Playlist } from '../../classes/playlist';
import './style.css';
import YouTube from 'react-youtube';
import VideoYoutube from './video-youtube';
import { useParams } from 'react-router-dom';
function PlayList() {
    const { videoId } = useParams();
    const [show, setShow] = useState(false);
    const [playlist, setplaylist] = useState<Playlist>();
    const [playlistItems, setplaylistItems] = useState<Item[]>();
    const [openVideo, setOpenVideo] = useState<Item>();
    const [playlistData, setplaylistData] = useState<Playlist>();
    const [image, setimage] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const YOUTUBE_PLAYLIST_ITEMS_API = 'https://www.googleapis.com/youtube/v3/playlistItems';
    const YOUTUBE_PLAYLIST_API = 'https://www.googleapis.com/youtube/v3/playlists';
    const YOUTUBE_API_KEY = process.env.REACT_APP_YT1

    useEffect(() => {
        async function getServerSideProps() {
            // const res = await fetch(`${YOUTUBE_PLAYLIST_ITEMS_API}?part=snippet&maxResults=50&playlistId=PLVHL_sCmXigEoBKB71QKKpbUIB_5XvZBk&key=${YOUTUBE_API_KEY}`)
            const res = await fetch(`${YOUTUBE_PLAYLIST_ITEMS_API}?part=snippet&maxResults=50&playlistId=${videoId}&key=${YOUTUBE_API_KEY}`)
            const data = await res.json();
            var newdata = data.items.filter((x: { snippet: { title: string; }; }) => x.snippet.title !== "Private video");
            setplaylist(data);
            setplaylistItems(newdata);
            setLoading(false)
        }
        getServerSideProps()

    }, [])

    useEffect(() => {
        async function getServerSideProps() {
            // const res = await fetch(`${YOUTUBE_PLAYLIST_API}?part=snippet&id=PLVHL_sCmXigEoBKB71QKKpbUIB_5XvZBk&key=${YOUTUBE_API_KEY}`)
            const res = await fetch(`${YOUTUBE_PLAYLIST_API}?part=snippet&id=${videoId}&key=${YOUTUBE_API_KEY}`)
            const data = await res.json();
            setplaylistData(data);
            setimage(data.items[0].snippet.thumbnails.medium.url);
            setLoading(false)
        }
        getServerSideProps()
    }, [])

    function getImage(item:Item){
        if(
            item !== undefined &&
            item.snippet !== undefined &&
            item.snippet.thumbnails !== undefined &&
            item.snippet.thumbnails.default !== undefined
            ) {
                return item.snippet.thumbnails.default.url;
            }
           
        return "https://upload.wikimedia.org/wikipedia/commons/2/28/X_mark_18x18_02.svg"
    }
    return <>
        {
            loading ? <div className='loader' /> :
            <Container>
                <Row>
                    <Col lg={4} xs={12} className="m-4">
                        <Card className='card'>
                            <div className='card-img-block'>
                                <img className="card-img-top" src={image} alt="Card image cap" />
                            </div>
                            <div className="card-body pt-0">
                                <h1 className="card-title">{playlistData?.items[0].snippet.title}</h1>
                                <p className="card-text">{playlistData?.items[0].snippet.description}</p>
                                <p className="card-text">{playlist?.pageInfo.totalResults} videos</p>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={6} xs={12}>
                        <ListGroup>
                            {playlistItems !== null &&
                                playlistItems?.map((item: Item) => (
                                    <ListGroup.Item key={item.etag} className='lista' onClick={() => { setOpenVideo(item); setShow(true) }}>

                                        <div className="d-flex w-100 justify-content-between">
                                            <img className="smallimg" src={getImage(item)} alt="Card image cap" />
                                            <h5 className="mb-1 textovideo">{item.snippet.title}</h5>
                                        </div>
                                    </ListGroup.Item>
                                ))
                            }
                        </ListGroup>

                    </Col>
                </Row>
            </Container>
        }

        <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {openVideo?.snippet.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='h-100'>
                <VideoYoutube id={openVideo?.snippet.resourceId.videoId || ""} />
            </Modal.Body>
        </Modal>
    </>
}
export default PlayList;
